import { availableLanguages } from '@/lang/availableLang'
import config from '@/config'

function getDefaultLang() {
  let defaultLang = availableLanguages.find(ele => ele == window.navigator.language.replace('-', '_'))
  if (!defaultLang) defaultLang = config.platformLang
  return defaultLang
}

export default {
  uuid: -1, //Not in use
  userName: '',
  individualUserName: '',
  userType: '',
  userTypeEnum: '',
  loginStatus: '',
  pendingQuestionnaireCompletion: false,
  openAccountEnabled: false,
  countryCode: '',
  accessToken: '',
  url: '',
  lang: getDefaultLang(),
  isChangedLangAtLandingPage: false,
  registerStep: 1,
  isDemo: false,
  tradingToolUrl: {},
  email: '',
  id3Pass: false,
  regulator: null,
  failedSecondTime: false,
  // user info
  employmentFinanceAnswers: [],
  tradingAnswers: [],
  autochartisToken: {},
  autochartisExpiryTime: '',
  autoNotifyStatus: true,
  emailMasked: '',
  checkedAuthStatus: [],
  authHeaders: {
    etxId: '',
    eCode: '',
    ptxId: '',
    pCode: '',
    tCode: '',
  },
  requiredAuths: {},
  loginCredentials: {},
  extras:{
    logoutStacktrace: false
  },
  userId: '',
  eventEmailId: '',
  eventMobileId: '',
}
