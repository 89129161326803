<template>
  <ul class="link-box">
    <li v-if="countryCode == inCountryCode" @click="indiaBankTransferClick" data-testid="indiaBankTransfer">
      <div class="top title">{{ $t('common.withdrawChannel.indiabanktransfer') }}</div>
      <div class="info">
        <div class="logo circle bank"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            1-6 {{ $t('deposit.default.hours') }}, 24/7
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <li v-if="isValidCountry && restrictedChina" @click="internationalClick(1)" data-testid="international">
      <div class="top title">{{ $t('deposit.default.channels.i12BankTransfer') }}</div>
      <div class="info">
        <div class="logo circle international"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            2-5 {{ $t('deposit.default.businessDays') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <li v-if="excludeEqualsIBTCountries && restrictedChina" @click="internationalClick(2)" data-testid="international2">
      <div class="top title">{{ $t('deposit.default.channels.i12BankTransfer') }}</div>
      <div class="info">
        <div class="logo circle international"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            2-5 {{ $t('deposit.default.businessDays') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <li v-if="isShowEuroSepa" @click="euroSepaClick" data-testid="euroSepa">
      <div class="top title">{{ $t('deposit.default.channels.eurosepa') }}</div>
      <div class="info">
        <div class="logo cc euroSepa"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            1-3 {{ $t('deposit.default.businessDays') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>

    <InfoDialog
      :visible.sync="showIbtDialog"
      :title="dialogContent[resInfoType].title"
      :infoType="resInfoType"
      @closCbDialog="showIbtDialog = false"
    >
      <div>{{ dialogContent[resInfoType].content }}</div>

      <template #actions>
        <Button buttonType="default" class="dialog-action-button" @click="goToPage(redirectPage)">
          {{ dialogContent[resInfoType].button }}
        </Button>
        <Button
          v-if="resInfoType === 'fail'"
          buttonType="default"
          outline
          class="dialog-action-button"
          @click="showIbtDialog = false"
        >
          {{ $t('register.btn.depositViaOther') }}
        </Button>
      </template>
    </InfoDialog>
  </ul>
</template>

<script>
import mixin from '@/mixins/page/deposit/deposit';
import { mapActions, mapState } from 'vuex';
import { statusConfig } from '@/constants/status';
import Button from '@/components/common/Button.vue';
import InfoDialog from '@/components/dialog/InfoDialog.vue';
import { DEPOSIT_PATHNAME, REGISTERROUTER } from '@/constants/route';

export default {
  mixins: [mixin],
  components: { InfoDialog, Button },
  data() {
    return {
      isValidCountry: this.$config.allowIBTDeposit({
        reg: this.$store.state.common.regulator,
        countryCode: this.$store.state.common.countryCode
      }),
      showIbtDialog: false,
      resInfoType: 'success',
      redirectPage: { name: REGISTERROUTER.FINANCIAL_INFO },
      depositPage: { name: 'depositFunds' }
    };
  },
  computed: {
    ...mapState('register', ['ibtPoiAuditStatus', 'ibtPoaAuditStatus']),
    dialogContent() {
      return {
        success: {
          title: this.$t('register.accOpeningVerification.createLiveAccount.successInfo2.title'),
          content: this.$t('register.accOpeningVerification.createLiveAccount.successInfo2.content'),
          button: this.$t('register.accOpeningVerification.createLiveAccount.successInfo2.start')
        },
        pending: {
          title: this.$t('register.financialVerification.generalInfo.title'),
          content: this.$t('register.financialVerification.generalInfo.content'),
          button: this.$t('register.btn.depositViaOther')
        },
        fail: {
          title: this.$t('register.financialVerification.identifyingRequiredInfo.title'),
          content: this.$t('register.financialVerification.identifyingRequiredInfo.content'),
          button: this.$t('verificationStatus.kyc.resubmit')
        }
      };
    }
  },
  mounted() {
    // update ibtPoa, ibtPoi status
    this.actionUpdateIBTStatus();
  },
  methods: {
    ...mapActions('register', ['actionUpdateIBTStatus']),
    checkIBTStatusAndShowDialog() {
      // complete status of both IBT poi, poa
      if (this.ibtPoiAuditStatus === statusConfig.completed && this.ibtPoaAuditStatus === statusConfig.completed)
        return true;

      // if IBT poi is submitted or reaudit
      if (this.ibtPoiAuditStatus === statusConfig.submitted || this.ibtPoiAuditStatus === statusConfig.reaudit) {
        // submitted or reaudit status of both IBT poi, poa
        if (
          this.ibtPoaAuditStatus === statusConfig.submitted ||
          this.ibtPoaAuditStatus === statusConfig.reaudit ||
          this.ibtPoaAuditStatus === statusConfig.completed
        ) {
          this.resInfoType = 'pending';
          this.redirectPage = null;
          this.showIbtDialog = true;
          return false;
        }
        if (this.ibtPoaAuditStatus === statusConfig.pending || this.ibtPoaAuditStatus === statusConfig.rejected) {
          this.resInfoType = 'fail';
          this.redirectPage = { name: REGISTERROUTER.ADDRESS_PROOF };
          this.showIbtDialog = true;
          return false;
        }
      }

      if (this.ibtPoiAuditStatus === statusConfig.completed) {
        if (this.ibtPoaAuditStatus === statusConfig.submitted || this.ibtPoaAuditStatus === statusConfig.reaudit) {
          this.resInfoType = 'pending';
          this.redirectPage = null;
          this.showIbtDialog = true;
          return false;
        } else {
          this.resInfoType = this.ibtPoaAuditStatus === statusConfig.init ? 'success' : 'fail';
          this.redirectPage = { name: REGISTERROUTER.ADDRESS_PROOF };
          this.showIbtDialog = true;
          return false;
        }
      }

      if (this.ibtPoiAuditStatus === statusConfig.init) {
        this.resInfoType = 'success';
        this.redirectPage = { name: REGISTERROUTER.FINANCIAL_INFO };
        this.showIbtDialog = true;
        return false;
      }

      // rejected or pending IBT poi
      if (this.ibtPoiAuditStatus === statusConfig.pending || this.ibtPoiAuditStatus === statusConfig.rejected) {
        this.resInfoType = 'fail';
        this.redirectPage = { name: REGISTERROUTER.ID_PROOF };
        this.showIbtDialog = true;
        return false;
      }

      return false;
    },
    goToPage(destination) {
      if (destination) this.$router.push(destination);
      this.showIbtDialog = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/deposit/depositList.scss';
.dialog-action-button {
  & + & {
    margin-top: 16px;
  }
}
</style>
