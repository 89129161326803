import { instance, instanceExternal } from '@/axios/index.js'
import qs from 'qs'

const baseUrl = '/api/'
const cpUrl = '/cp/api/'
const securitys = `${baseUrl}security/getSecuritys/`
const withdrawRule = `${baseUrl}security/current/`

const loginUrl = `${baseUrl}login/`
const fileUrl = `${baseUrl}file/`
const smsUrl = `${baseUrl}request-sms-code`
const smsVerifyUrl = `${baseUrl}verify-sms-code`
const homeUrl = `${cpUrl}home/`
const withdrawalUrl = `${cpUrl}withdrawal/`
const paymentSelectionUrl = `${baseUrl}payment/`
const transferUrl = `${cpUrl}transfer/`
const depositUrl = `${cpUrl}deposit/`
const accountUrl = `${cpUrl}account/`
const cardRegisterUrl = `${cpUrl}card-register/`
const passwordUrl = `${cpUrl}password/`
const profileUrl = `${cpUrl}profile/`
const fundsUrl = `${cpUrl}funds/`
const transactionHistoryUrl = `${cpUrl}transactionHistory/`
const questionnaireUrl = `${cpUrl}questionnaire/`
const depositlResultPath = 'result'
const checkResetPasswordAuthorization = `${profileUrl}check_reset_password_authorization`
const submitResetProfilePassword = `${profileUrl}submit_reset_profile_password`
const submitResetChangeIpWarn = `${profileUrl}reset_change_ip_warn`
const submitResetMt4AccountPassword = `${cpUrl}password/submit_reset_mt4_password`
const submitResetForgetPassword = `${cpUrl}password/reset-mobile-password`
const identifyProofUrl = `${cpUrl}identityProof/`
const userProfileUrl = `${baseUrl}userProfile/`
const tcmktUrl = `${baseUrl}tcmkt/`
const tcVideoUrl = `${baseUrl}newsFactory/`
const tradingTips = `${baseUrl}tradingTips/`
const demoUrl = `${cpUrl}demo/`
const locationTrace = `https://secure.vantagefx.com/cdn-cgi/trace`
const googleTranslateBasic = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyCKuPDmPYUTdOFUitGsQzH3rLgs58B4_I0`
const notificationUrl = `${baseUrl}notification/`
const bannerUrl = `${baseUrl}banner/`
const sliderCheckerUrl = `${baseUrl}sliderChecker/`
const systemUrl = `${baseUrl}system/`
const autochartistUrl = `${cpUrl}autochartist/`
const campaignUrl = `${baseUrl}campaign/`
const cardAuditUrl = `${cpUrl}card-audit/`
const mfaUrl = `${baseUrl}mfa/`
const authUrl = `${cpUrl}two-factor/`

export const personalDetailUrl = `${baseUrl}personalDetail/`
export const verificationUrl = `${baseUrl}verificationV2/`
export const questionUrl = `${baseUrl}question/`
export const cpsUrl = `${depositUrl}cps/`
export const cpsWithdrawalUrl = `${withdrawalUrl}`
export const propTradingUrl = `${baseUrl}propTrading/`
export const userUrl = `${cpUrl}user/`
export const securityUrl = `${cpUrl}multiFactorAuth/`

/** login */
export const apiTo_login = data => instance.post(`${loginUrl}to_login`, qs.stringify(data))
export const apiTo_logout = data => instance.get(`${loginUrl}to_logout`, { headers: { Pragma: 'no-cache' }, params: data })
export const apiTo_login_by_token = headers => instance.post(`${loginUrl}to_login_by_token`, '', headers)
export const apiTo_login_register = data => instance.post(`${loginUrl}to_login_register`, qs.stringify(data))
export const apiTo_sms_code_login = data => instance.post(`${loginUrl}login-after-sms-code-check`, qs.stringify(data))

/** home */
export const apiQueryMetaTraderAccountDetails = data =>
  instance.post(`${homeUrl}query-metaTrader-account-details`, data)
export const apiQueryLeverageStatuses = data => instance.post(`${homeUrl}query-leverage-statuses`, data)
export const apiQueryAvailableLeverages = data => instance.post(`${homeUrl}query-available-leverages`, data)
export const apiUpdate_leverage = data => instance.post(`${homeUrl}update-leverage`, data, { timeout: 30000 })
export const apiUpdate_leverage_demo = data => instance.post(`${homeUrl}update-leverage-demo`, data, { timeout: 30000 })
export const apiReactivate_Account = data => instance.post(`${homeUrl}reactivate-account`, data)
export const apiHomeGet_demo_accs = data => instance.get(`${homeUrl}get-demo-accs`, data)
export const apiupdate_balance_demo = data => instance.post(`${homeUrl}update-balance-demo`, data)
export const apiRestAccountBalance = data => instance.post(`${homeUrl}restAccount/${data}`) // live account reset balance

/** userProfile*/
export const apiSetlanguage = data => instance.post(`${userProfileUrl}setlanguage`, qs.stringify(data))

/** withdrawal */
export const apiGetSecuritys = data => instance.get(`${securitys}`, { param: data })

export const apiIsCreditCardDeposit_cp = data => instance.post(`${withdrawalUrl}isCreditCardDeposit_cp`, data)
export const apiGetWithdrawalData_cp = () => instance.post(`${withdrawalUrl}getWithdrawalData_cp`)
export const apiGetNonCreditCardWithdrawTypeCP = data =>
  instance.post(`${withdrawalUrl}getNonCreditCardWithdrawTypeCP`, data)
export const apiApplyWithdrawal_cp = (data, token) =>
  instance.post(`${withdrawalUrl}applyWithdrawal_cp`, data, { headers: { token: token } })
export const apiRegisterUnionPayCard_cp = data => instance.post(`${withdrawalUrl}registerCard`, data)
export const apiGetWithdrawDeductCredit = data => instance.post(`${withdrawalUrl}getDeductCredit`, data)
export const apiCardInformation = data => instance.get(`${withdrawalUrl}queryUnionpayCard`, { param: data })
export const apiWithdrawalBlacklist = () => instance.get(`${withdrawalUrl}queryBlacklist`)
export const apiCreditCardBalances = data => instance.get(`${withdrawalUrl}creditCard/balances/${data.currency}`, data)
export const apiGetLBTWithdrawalData_cp = data =>
  instance.get(`${withdrawalUrl}getLBTWithdrawalData_cp`, { params: data })
export const apiApplyWithdrawal_cp_batch = (data, token) =>
  instance.post(`${withdrawalUrl}applyWithdrawal_cp_batch`, data, { headers: { token: token } })
export const apiCancelWithdraw = data =>
  instance.post(`${withdrawalUrl}cancelWithdrawalOrder?withdrawalId=${data.withdrawalId}`)
export const apiSendEmailVerificationCode = data => instance.post(`${mfaUrl}sendEmailVerificationCode`, data)
export const apiNonCreditCardWithdrawTypesMinimumAmount = () =>
  instance.get(`${withdrawalUrl}nonCreditCardWithdrawTypesMinimumAmount`)

/** Payment */
export const apiPaymentSelection = data => instance.post(`${paymentSelectionUrl}info/getPaymentInfoList/`, data)
export const apiPaymentInfo = withdrawType => instance.get(`${paymentSelectionUrl}info/${withdrawType}/`)
export const apiPaymentGetInfo = data => instance.post(`${paymentSelectionUrl}info/getInfo/`, data)

/** transfer */
export const apiGetTransferData_cp = () => instance.post(`${transferUrl}getTransferData_cp`)
export const apiApplyTransfer_cp = (data, token) =>
  instance.post(`${transferUrl}applyTransfer_cp`, qs.stringify(data), { headers: { token: token } })
export const apiTransferPermission = data => instance.post(`${transferUrl}transferPermission`, qs.stringify(data))
export const apiTransferBlacklist = () => instance.get(`${transferUrl}queryBlacklist`)
//Get transferable currency based on selected account's currency
export const apiGetTransferableCurrency = data => instance.get(`${transferUrl}currencyList?formCurrency=${data}`)
export const apiGetTransferToRate = data =>
  instance.get(`${transferUrl}currencyRate?formCurrency=${data.formCurrency}&&toCurrency=${data.toCurrency}`)

/** file */
export const apiDelete_file = data => instance.post(`${fileUrl}delete`, data)

/** account */
export const apiGetMetaTraderAccounts = () => instance.get(`${accountUrl}getMetaTraderAccounts`)
export const apiApplyAdditionalAccount = data =>
  instance.post(`${accountUrl}applyAdditionalAccount`, qs.stringify(data))
export const apiCheckEquity = data =>
  instance.get(`${accountUrl}check_equity?mt4Account=${data.mt4Account}&&amount=${data.amount}`)
export const apiCreateDemoAccount = data => instance.post(`${accountUrl}createDemoAccount`, data)
export const apiGetAutoStartTradingThreshold = () => instance.get(`${accountUrl}getAutoStartTradingThreshold`)
export const apiGetFirstAccountAuditStatus = () => instance.get(`${accountUrl}getFirstAccountAuditStatus`)

/** deposit */
export const apiDepositBlacklist = () => instance.get(`${depositUrl}queryBlacklist`)
export const apiRestrictedDeposit = () => instance.post(`${depositUrl}getRestrictedDepositData`)
export const apiCc_payment = data => instance.post(`${depositUrl}cc_payment`, data)
export const apiCc_payment_maxis = data => instance.post(`${depositUrl}cc_paymaxis_payment`, data)
export const apiCc_payment_365 = data => instance.post(`${depositUrl}cc_payment_365`, data)
export const apiCc_payment_solid = (data, token) => instance.post(`${depositUrl}cc_solidpay`, data, { headers: { token: token } })
export const apiCc_solid_order = (data, token) => instance.post(`${depositUrl}solid-payment/order`, data, { headers: { token: token } })
export const apiCc_payment_apg = data => instance.post(`${depositUrl}cc_payment_apg`, data)
export const apiCc_payment_sd = data => instance.post(`${depositUrl}cc_payment_sd`, data)
export const apiCc_payment_worldcard = (data, token) => instance.post(`${depositUrl}cc_payment_worldcard`, data, { headers: { token: token } })
export const apiCc_payment_bridgerPay = (data, token) =>
  instance.post(`${depositUrl}bridgepay`, data, { headers: { token: token } })
export const apiFetch_card = data => instance.post(`${depositUrl}fetch_card`, data)
export const apiUpload_card = data => instance.post(`${depositUrl}upload_cc_img`, data)
export const apiQuery_mt_accounts = data => instance.post(`${depositUrl}query_mt_accounts`, qs.stringify(data))
export const apiQueryRate_USD_VND = () => instance.post(`${depositUrl}queryRate_USD_VND`)
export const apiRequire_token = () => instance.post(`${depositUrl}require_token`)
export const apiAnti_Reuse = () => instance.get(`${baseUrl}token/anti-reuse`)
export const apiQueryRate = () => instance.post(`${depositUrl}queryRate`)
export const apiQuery_paymentChannel = () => instance.post(`${depositUrl}query_paymentChannel`)
export const apiQueryRate_USD_NGN = () => instance.post(`${depositUrl}queryRate_USD_NGN`)
export const apiQueryRate_USD_MYR = () => instance.post(`${depositUrl}queryRate_USD_MYR`)
export const apiQueryRate_USD_IDR = () => instance.post(`${depositUrl}queryRate_USD_IDR`)
export const apiQueryRate_USD_KRW = () => instance.post(`${depositUrl}queryRate_USD_KRW`)
export const apiQueryRate_USD_INR = () => instance.post(`${depositUrl}queryRate_USD_INR`)
export const apiQueryRate_USD_PHP = () => instance.post(`${depositUrl}queryRate_USD_PHP`)
export const apiQueryRate_USD_BRL = () => instance.post(`${depositUrl}queryRate_USD_BRL`)
export const apiQueryRate_USD_ZAR = () => instance.post(`${depositUrl}queryRate_USD_ZAR`)
export const apiQueryRate_GBP_NGN = () => instance.post(`${depositUrl}queryRate_GBP_NGN`)
export const apiQueryRate_USD_JPY = () => instance.post(`${depositUrl}queryRate_USD_JPY`)
export const apiQueryRate_USD_HKD = () => instance.post(`${depositUrl}queryRate_USD_HKD`)

// for all currencies rates
export const apiQueryRateUSDByTargetCurrency = currency =>
  instance.post(`${depositUrl}queryRateUSDByTargetCurrency`, qs.stringify({ targetCurrency: currency }))

export const apiQuery_malaysia_paymentChannel = () => instance.post(`${depositUrl}query_malaysia_paymentChannel`)
export const apiGet_rate = data => instance.post(`${depositUrl}get_rate`, qs.stringify(data))
export const apiGet_holder_name = data => instance.post(`${depositUrl}get_holder_name`, qs.stringify(data))
export const apiGet_mobile_rate = data => instance.post(`${depositUrl}get_mobile_rate`, qs.stringify(data))
export const apiGetDepositData_cp = () => instance.post(`${depositUrl}getDepositData_cp`)
export const apiExchangeRate = data => instance.get(`${baseUrl}exchange-rate?from=${data.from}&to=${data.to}`)
export const apiExchangeDepositRate = data =>
  instance.get(`${depositUrl}exchangeRate?fromCurrency=${data.fromCurrency}&toCurrency=${data.toCurrency}`)
export const apiQueryExchangeRate = () => instance.get(`${depositUrl}queryExchangeRate`)
export const apiGetSecuritysRule = data => instance.get(`${withdrawRule}${data.functionCode}/rule`)

export const apiVietnam_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}vietnam_bankwire_payment`, data, { headers: { token: token } })
export const apiXpay_payment = (data, token) =>
  instance.post(`${depositUrl}xpay_payment`, data, { headers: { token: token } })
export const apiUnionpay_transfer = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer`, data, { headers: { token: token } })
export const apiUnionpay = (data, token) => instance.post(`${depositUrl}unionpay`, data, { headers: { token: token } })
export const apiZotapay_payment = (data, token) =>
  instance.post(`${depositUrl}zotapay_payment`, data, { headers: { token: token } })
export const apiPaytoday_payment = (data, token) =>
  instance.post(`${depositUrl}paytoday_payment`, data, { headers: { token: token } })
export const apiSkrill_payment = (data, token) =>
  instance.post(`${depositUrl}skrill/payment`, data, { headers: { token: token } })
export const apiStic_payment = (data, token) =>
  instance.post(`${depositUrl}stic/payment`, data, { headers: { token: token } })
export const apiPoli_payment = (data, token) =>
  instance.post(`${depositUrl}poli_payment`, data, { headers: { token: token } })
export const apiNigeria_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}nigeria_bankwire_payment`, data, { headers: { token: token } })
export const apiNeteller_payment = (data, token) =>
  instance.post(`${depositUrl}paysafe_neteller`, data, { headers: { token: token } })
export const apiPaywize_payment = (data, token) =>
  instance.post(`${depositUrl}paywize_payment`, data, { headers: { token: token } })
export const apiBridge_payment = (data, token) =>
  instance.post(`${depositUrl}bridgepay`, data, { headers: { token: token } })
export const apiPaypal_payment = (data, token) =>
  instance.post(`${depositUrl}paypal_payment`, data, { headers: { token: token } })
export const apiTrustly_payment = (data, token) =>
  instance.post(`${depositUrl}trustly_payment`, data, { headers: { token: token } })
export const apiCardpay_payment = (data, token) =>
  instance.post(`${depositUrl}cardpay_payment`, data, { headers: { token: token } })
export const apiTinkBit_payment = (data, token) =>
  instance.post(`${depositUrl}tinkbit_payment`, data, { headers: { token: token } })
export const apiMobile_payment = (data, token) =>
  instance.post(`${depositUrl}mobile_payment`, data, { headers: { token: token } })
export const apiMalaysia_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}malaysia_bankwire_payment`, data, { headers: { token: token } })
export const apiPhilipiness_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}philippines_bankwire_payment`, data, { headers: { token: token } })
export const apiIndonesia_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}indonesia_bankwire_payment`, data, { headers: { token: token } })
export const apiInternational_bank_payment = (data, token) =>
  instance.post(`${depositUrl}international_bank_payment`, data, { headers: { token: token } })
export const apiInternational_bank_payment_equals = (data, token) =>
  instance.post(`${depositUrl}international_bank_payment/equals`, data, { headers: { token: token } })
export const apiFasapay_payment = (data, token) =>
  instance.post(`${depositUrl}fasapay_payment`, data, { headers: { token: token } })
export const apiMijipay_payment = (data, token) =>
  instance.post(`${depositUrl}mijipay_payment`, data, { headers: { token: token } })
export const apiBroker_to_broker_payment = (data, token) =>
  instance.post(`${depositUrl}broker_to_broker_payment`, data, { headers: { token: token } })
export const apiBpay_payment = (data, token) =>
  instance.post(`${depositUrl}bpay_payment`, data, { headers: { token: token } })
export const apiAustralian_bank_payment = (data, token) =>
  instance.post(`${depositUrl}australian_bank_payment`, data, { headers: { token: token } })
export const apiCrypto_payment = (data, token) =>
  instance.post(`${depositUrl}crypto_payment`, data, { headers: { token: token } })
export const apiCryptousdt_payment = (data, token) =>
  instance.post(`${depositUrl}cryptousdt_payment`, data, { headers: { token: token } })
export const apiCryptoBtcCps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_btc`, data, { headers: { token: token } })
export const apiCryptoOmniCps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_omni`, data, { headers: { token: token } })
export const apiCryptousdtcps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_usdt`, data, { headers: { token: token } })
export const apiCryptoTrcCps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_trc`, data, { headers: { token: token } })
export const apiBitwallet_payment = (data, token) =>
  instance.post(`${depositUrl}bitwallet`, data, { headers: { token: token } })
export const apiDragonPhoenix_payment = (data, token) =>
  instance.post(`${depositUrl}kr_df`, data, { headers: { token: token } })
export const apiEbuy_payment = (data, token) => instance.post(`${depositUrl}ebuy`, data, { headers: { token: token } })
export const apiAstropay = (data, token) => instance.post(`${depositUrl}astropay`, data, { headers: { token: token } })
export const apiIndonesiaZotapay = (data, token) =>
  instance.post(`${depositUrl}indonesiaZotapay`, data, { headers: { token: token } })
export const apiIndiaZotapay = (data, token) =>
  instance.post(`${depositUrl}indiaZotapay`, data, { headers: { token: token } })
export const apiSouthAfricaZotapay = (data, token) =>
  instance.post(`${depositUrl}southAfricaZotapay`, data, { headers: { token: token } })
export const apiZotapay_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}zotapay_bankwire_payment`, data, { headers: { token: token } })
export const apiPerfectMoney = (data, token) =>
  instance.post(`${depositUrl}perfectmoney`, data, { headers: { token: token } })
export const apiIndiaP2PSubmitReceipt = (data, token) =>
  instance.post(`${depositUrl}submit-india-p2p`, data, { headers: { token: token } })
export const apiPaystack_payment = (data, token) =>
  instance.post(`${depositUrl}paystack`, data, { headers: { token: token } })
export const apiNigeriaP2P_payment = (data, token) =>
  instance.post(`${depositUrl}nigeria_p2p`, data, { headers: { token: token } })
export const apiUaeP2P_payment = (data, token) =>
  instance.post(`${depositUrl}uae_p2p`, data, { headers: { token: token } })
export const apiLocalDepositor_transfer = (data, token) =>
  instance.post(`${depositUrl}localdepositor`, data, { headers: { token: token } })
export const apiLocalDepositor_depositor = data => instance.get(`${depositUrl}localdepositor/depositors`, data)
export const apiIDeal_banxa_payment = (data, token) =>
  instance.post(`${depositUrl}ideal-banxa`, data, { headers: { token: token } })
  export const apiEuro_sepa = (data, token) =>
  instance.post(`${depositUrl}euro-sepa`, data, { headers: { token: token } })

/** cardRegister */
export const apiCredit_card = data => instance.post(`${cardRegisterUrl}credit_card`, data)
export const apiHistoryList = data => instance.post(`${cardRegisterUrl}history/list`, data)
export const apiGetCardDetials = data =>
  instance.get(`${cardRegisterUrl}get-card-info?cardId=${data.cardId}&&cardType=${data.cardType}`)
export const apiRemoveCard = data => instance.post(`${cardRegisterUrl}unionpay_card/update`, data)
export const apiUpdateCreditCard = data => instance.post(`${cardRegisterUrl}/credit_card/update`, data)

/**card audit */
export const cardAuditSubmit = data => instance.post(`${cardAuditUrl}submit`, data)

/** password */
export const apiUpdate_mt4_account_password = data => instance.post(`${passwordUrl}update_mt4_account_password`, data)
export const apiReq_reset_mt4_password = data => instance.post(`${passwordUrl}req_reset_mt4_password`, data)
export const apiCheck_new_password = data => instance.post(`${passwordUrl}check_new_password`, data)
export const apiCheck_current_password = data => instance.post(`${passwordUrl}check_current_password`, data)

/** profile */
export const apiInfo = () => instance.post(`${profileUrl}info`)
export const apiCheckPassword = data => instance.post(`${profileUrl}checkPassword`, qs.stringify(data))
export const apiEncryptPublicKey = (token = null) => instance.get(`${cpUrl}keyPair/pk?token=${token}`)
export const apiUpdatePassword = (data, publicKey) =>
  instance.post(`${profileUrl}updatePassword`, qs.stringify(data), { headers: { pk: publicKey } })
export const apiReq_reset_profile_password = data =>
  instance.post(`${profileUrl}req_reset_profile_password`, qs.stringify(data))
export const apiForceUpdatePassword = data => instance.post(`${profileUrl}force_update_password`, data)
export const apiReq_reset_profile_password_mobile = data => instance.post(`${smsVerifyUrl}`, data)
export const apiReq_reset_get_sms = data => instance.post(`${smsUrl}`, data)
export const apiSubmitResetChangeIpWarn = data => instance.post(`${submitResetChangeIpWarn}`, qs.stringify(data))
export const apiCheckMfaSwitchd = () => instance.get(`${profileUrl}checkMfaSwitch`)

/** funds */
export const apiTransferHistoryGet = data =>
  instance.get(`${fundsUrl}transfer-history/get`, {
    params: data,
  })

/** identityProof */
export const apiVerificationIdentityProofs = data => instance.post(`${identifyProofUrl}verification`, data)
export const apiCheckRecordExists = data => instance.post(`${identifyProofUrl}check_record_exists`, data)
// export const apiGet_last_identifiy_proof = id =>
//   instance.get(`${identifyProofUrl}get_last_identifiy_proof?documentType=${id}`)
export const apiGet_last_identifiy_proof = (id, auditType = 1) =>
  instance.get(`${identifyProofUrl}get_last_identifiy_proof`, {
    params: {
      documentType: id,
      auditType,
    },
  })
export const apiAttach_new_document = data => instance.post(`${identifyProofUrl}attach_new_document`, data)
export const apiUpdate_user_id_address = data => instance.post(`${identifyProofUrl}updateUserIdAddress`, data)
export const apiVerification2IdentityProofs = data => instance.post(`${identifyProofUrl}verificationV2`, data)
export const apiDoId3 = data => instance.post(`${identifyProofUrl}doID3`, data)

/**
 *Thirdparty Deposit Result
 * @param {*} url
 * @param {*} data
 */
export const apiThirdPartyDepositResult = (paymentType, data) =>
  instance.post(`${depositUrl}` + paymentType + `/${depositlResultPath}`, data, {
    timeout: 30000,
  })

/** transactionHistory */
export const apiTransactionHistory = (url, data) => instance.post(`${transactionHistoryUrl}${url}`, qs.stringify(data))
export const apiTransactionHistoryDetail = (withdrawId, type) =>
  instance.get(`${transactionHistoryUrl}withdrawDetail`, {
    params: {
      id: withdrawId,
      type,
    },
  })
export const apiDownloadAccountTransactionHistoryPDF = data =>
  instance.post(`${transactionHistoryUrl}/downloadAccountTransactionHistoryPDF`, data)

/** questionnaireUrl */
export const apiCheck = data => instance.post(`${questionnaireUrl}check`, data)

/**
 * reset password
 */
export const apiCheckResetPasswordAuthorization = data => instance.post(`${checkResetPasswordAuthorization}`, data)
export const apiSubmitResetProfilePassword = (data, publicKey) =>
  instance.post(`${submitResetProfilePassword}`, qs.stringify(data), { headers: { pk: publicKey } })
export const apiSubmitResetMt4AccountPassword = data => instance.post(`${submitResetMt4AccountPassword}`, data)
export const apiSubmitReseForgetPassword = data => instance.post(`${submitResetForgetPassword}`, data)

/** tcmktUrl */
export const apiTcmktCheckDeposit = () => instance.get(`${tradingTips}check/deposit`)
export const apiTcmktCheckTnc = () => instance.post(`${tcmktUrl}check/tnc`)
export const apiTcmktTncAccept = () => instance.post(`${tcmktUrl}tnc/accept`)
export const apiTcmktGetUrl = () => instance.post(`${tcmktUrl}getUrl`)

/** tcvideoUrl */
export const apiProCheckUnlocked = () => instance.get(`${tradingTips}/unlocked`)
export const apiVideoCheckDeposit = () => instance.get(`${tradingTips}check/deposit`)
export const apiVideoCheckTnc = () => instance.post(`${tcVideoUrl}check/tnc`)
export const apiVideoTncAccept = () => instance.post(`${tcVideoUrl}tnc/accept`)

/** demoUrl */
export const apiGet_demo_accs = () => instance.get(`${demoUrl}get_demo_accs`)

/** locationTrace */
//https://www.cloudflare.com/cdn-cgi/trace get location info by tracing cloudflare
export const apiGetUserLocation = () => instanceExternal.get(`${locationTrace}`)

/** notification */
export const apiGetNotifications = ({ country }) => instance.get(`${notificationUrl}enabled?country=${country}`)

/** Verification */
export const apiSecurityPassed = data =>
  instance.get(`${sliderCheckerUrl}passed`, {
    params: data,
  })

/** systemUrl */
export const apiInformation = () => instance.get(`${systemUrl}information`)

/** questionUrl */
export const apiEmploymentFinanceAnswers = () => instance.get(`${questionUrl}employmentFinanceAnswers`)
export const apiTradingAnswers = () => instance.get(`${questionUrl}tradingAnswers`)

/** autochartist */
export const apiAutochartistToken = data =>
  instance.post(`${autochartistUrl}token?userType=${data.userType}&expiryTime=${data.expiryTime}`)

/** promotion campaign */
export const apiApplyNoBonusCampaign = data => instance.post(`${campaignUrl}no-deposit-bonus/apply`, data)
export const apiCheckEligibilityNoBonusCampaign = () => instance.get(`${campaignUrl}no-deposit-bonus/eligible`)
export const apiCheckInfoNoBonusCampaign = () => instance.get(`${campaignUrl}no-deposit-bonus/info`)

export const apiReferralLink = () => instance.get(`${campaignUrl}refer-a-friend/v3/campaign-code`)
export const apiReferralSubmitRedeem = data => instance.post(`${campaignUrl}refer-a-friend/v3/redeem`, data)
export const apiReferralList = () => instance.get(`${campaignUrl}refer-a-friend/v3/participants`)
export const apiReferralEligibility = () => instance.get(`${campaignUrl}refer-a-friend/v3/eligible`)
export const apiReferralInfo = () => instance.get(`${campaignUrl}refer-a-friend/v3/info`)

export const apiEligibleCampaigns = () => instance.get(`${campaignUrl}eligible-campaigns`)
export const apiCampaignInfo = (id, langUsed) =>
  instance.get(`${campaignUrl}participants?campaignId=${id}${langUsed ? `&languageCode=${langUsed}` : ''}`)
export const apiParticipatedCampaigns = id => instance.get(`${campaignUrl}participated-campaigns`)
export const apiCampaignOpt = (id, optIn) => instance.post(`${campaignUrl}opt-in?campaignId=${id}&optIn=${optIn}`)
export const apiCampaignOptStatus = id => instance.get(`${campaignUrl}opt-in?campaignId=${id}`)
export const apiJoinedDepositBonusCampaign = () => instance.get(`${campaignUrl}joined-deposit-bonus-campaign`)
export const apiGetRedeemableAccounts = id => instance.get(`${campaignUrl}redeemable-accounts?campaignId=${id}`)
export const apiOptInWithAccount = data => instance.post(`${campaignUrl}opt-in-with-account`, data)
export const apiOptOutWithAccount = data => instance.post(`${campaignUrl}opt-out-with-account`, data)
export const apiCashbackTradingRewardHistories = id =>
  instance.get(`${campaignUrl}cashback/tradingRewardHistories?campaignId=${id}`)
export const apiRedeem = data => instance.post(`${campaignUrl}redeem`, data)

/** 2022 xmas make a wish campaign api */
export const apiSpinTheWheel = data => instanceExternal.post(`${campaignUrl}xMakeAWish/spinTheWheel`, data)
export const apiXMakeAWishRedeem = data => instance.post(`${campaignUrl}xMakeAWish/redeem`, data)

/** propTrading */
export const apiPropTradingSettings = data => instance.get(`${propTradingUrl}settings`)

export const apiPropTradingProceedPayment = (data, token) =>
  instance.post(`${propTradingUrl}proceedPayment`, data, { headers: { token: token } })
export const apiPropTradingProceedPaymentCPS = (data, token) =>
  instance.post(`${propTradingUrl}proceedPaymentCPS`, data, { headers: { token: token } })

export const apiGetDashBoardAccountList = (accountStatus, accountStage) => {
  return instance.get(`${propTradingUrl}getDashBoardAccountList${queryStringBuilder({ accountStatus, accountStage })}`)
}
export const apiGetDashBoardSessionList = sessionStatus => {
  return instance.get(`${propTradingUrl}getDashBoardSessionList${queryStringBuilder({ sessionStatus })}`)
}

export const apiGetAccountMetrics = accountNumber =>
  instance.get(`${propTradingUrl}getAccountMetrics?accountNumber=${accountNumber}`)

export const apiEligiblePropTrading = () => instance.get(`${propTradingUrl}eligible-propTrading`)

export const apiUpdateAccountIsNew = data =>
  instance.post(`${propTradingUrl}updateAccountIsNew${queryStringBuilder(data)}`)

export const apiResetPassword = data => instance.post(`${propTradingUrl}resetPassword`, data)

export const apiForgetPassword = data => instance.post(`${propTradingUrl}forgetPassword`, data)
export const apiResetPasswordByToken = data => instance.post(`${propTradingUrl}resetPasswordByToken`, data)

export const apiCheckPromoAvailable = (feeSettingId = '', promoCode = '') =>
  instance.get(`${propTradingUrl}checkPromoAvailable?feeSettingId=${feeSettingId}&promoCode=${promoCode}`)

export const apiGetSessionHistory = sessionId =>
  instance.get(`${propTradingUrl}getSessionHistory?sessionId=${sessionId}`)

export const apiGetServerTime = () => instance.get(`${systemUrl}getServerTime`)

export const apiGetCertificationPDFList = () => instance.get(`${propTradingUrl}getCertificationPDFList`)
export const apiDownloadCertificationPDF = (sessionNumber, accountStage) =>
  instance.get(
    `${propTradingUrl}/downloadCertificationPDF?sessionNumber=${sessionNumber}&accountStage=${accountStage}`,
    { responseType: 'blob' }
  )

export const apiGetAccountGraph = accountNumber =>
  instance.get(`${propTradingUrl}getAccountGraph?accountNumber=${accountNumber}`)
export const apiPayoutInfo = (accountNumber, paymentType) =>
  instance.get(`${propTradingUrl}pay-out/info?accountNumber=${accountNumber}&paymentType=${paymentType}`)
export const apiPropTradingPayout = (data, token) =>
  instance.post(`${propTradingUrl}pay-out/all`, data, { headers: { token: token } })
export const apiPtGetNonCreditCardWithdrawTypeCP = data =>
  instance.post(`${propTradingUrl}pay-out/getNonCreditCardWithdrawTypeCP`, data)

/** Security */
export const apiGetSecurityInfo = () => instance.get(`${securityUrl}info`)
export const apiGetHint = () => instance.get(`${securityUrl}hintInfo`)
export const apiGetMultiFactorAuth = functionCode => instance.get(`${securityUrl}${functionCode}/getMultiAuthMethod`)
export const apiAuthStatus = () => instance.get(`${authUrl}status`)
export const apiPreValidateTotp = data => instance.post(`${securityUrl}totp/preValidate`, data)
export const apiPreValidateEmail = data => instance.post(`${securityUrl}email/preValidate`, data)

export const apiChangeEmail = data => instance.post(`${securityUrl}changeEmail`, data)
export const apiChangeSwitch = data => instance.post(`${securityUrl}switch`, data)
export const apiVerifyEmail = () => instance.post(`${securityUrl}verifyEmail`)
export const apiAddTwoFactor = data => instance.post(`${authUrl}enable`, data)
export const apiModifyTwoFactor = data => instance.post(`${securityUrl}totp/change/${data}`)

// Trading Contest
export const apiTradingContestEligibility = () => instance.get(`${campaignUrl}eligible-trading-contests`)
export const apiGetTradingContestParticipantInfo = body =>
  instance.get(`${campaignUrl}trading-contest-info`, { params: body })
export const apiGetTradingContestUsers = body => instance.get(`${campaignUrl}tradingContest/users`, { params: body })
export const apiApplyTradingContestAccount = data =>
  instance.post(`${campaignUrl}tradingContest/applyAdditionalAccount`, qs.stringify(data))
export const apiTradingContestOpt = params =>
  instance.post(`${campaignUrl}tradingContest/opt-in?${qs.stringify(params)}`)

// refactor: extract to utils
const queryStringBuilder = (data = {}) => {
  const qs = []
  for (const [k, v] of Object.entries(data)) {
    if (v || Number.isInteger(v)) qs.push(`${k}=${v}`)
  }

  const result = qs.filter(Boolean).join('&')

  return `${result ? '?' : ''}${result}`
}

/** banner */
// displayPlatform standadize with BE (BannerDisplayPlatformEnum) & Admin (displayPlatformEnum)
// displayPage standadize with BE (BannerDisplayPageEnum) & CP (popupBannerDisplayPageEnum)
export const apiGetBanners = ({ country, displayPage }) =>
  instance.get(`${bannerUrl}enabled?displayPlatform=1&country=${country}&displayPage=${displayPage}`)
export const apiSnoozeBanner = notificationIdList => instance.post(`${bannerUrl}snooze`, { notificationIdList })
