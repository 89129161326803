const bankInfoDetails = {
  1: {
    currency: ['EUR', 'USD', 'GBP', 'AUD', 'NZD', 'HKD', 'CAD', 'SGD', 'JPY', 'USC'],
    bank_name: { show: true, value: 'Equals Money UK' },
    branch_address: {
      AUD: { show: true, value: 'Vintners Place 68 Upper Thames St, London EC4V 3BJ United Kingdom' },
      USD: { show: true, value: 'Vintners Place 68 Upper Thames St, London EC4V 3BJ United Kingdom' },
      USC: { show: true, value: 'Vintners Place 68 Upper Thames St, London EC4V 3BJ United Kingdom' },
      EUR: { show: true, value: 'Vintners Place 68 Upper Thames St, London EC4V 3BJ United Kingdom' },
      GBP: { show: true, value: 'Vintners Place 68 Upper Thames St, London EC4V 3BJ United Kingdom' },
      JPY: { show: true, value: 'Vintners Place 68 Upper Thames St, London EC4V 3BJ United Kingdom' },
      CAD: { show: true, value: 'Vintners Place 68 Upper Thames St, London EC4V 3BJ United Kingdom' },
      SGD: { show: true, value: 'Vintners Place 68 Upper Thames St, London EC4V 3BJ United Kingdom' },
      NZD: { show: true, value: 'Vintners Place 68 Upper Thames St, London EC4V 3BJ United Kingdom' },
      HKD: { show: true, value: 'Vintners Place 68 Upper Thames St, London EC4V 3BJ United Kingdom' },
    },
    branch_name: {
      AUD: { show: true, value: 'Town Hall Sydney' },
      USD: { show: true, value: 'Town Hall Sydney' },
      USC: { show: true, value: 'Town Hall Sydney' },
      EUR: { show: true, value: 'Town Hall Sydney' },
      GBP: { show: true, value: 'Town Hall Sydney' },
      JPY: { show: true, value: 'Town Hall Sydney' },
      CAD: { show: true, value: 'George & Market Sts Sydney branch' },
      SGD: { show: true, value: 'George & Market Sts Sydney branch' },
      NZD: { show: true, value: 'George & Market Sts Sydney branch' },
      HKD: { show: true, value: 'George & Market Sts Sydney branch' },
    },
    account_name: { show: true, value: 'PU Prime (PTY) LTD Client' },
    account_number: {
      AUD: { show: true, value: 'GB69SPPV23188492214846' },
      USD: { show: true, value: 'GB69SPPV23188492214846' },
      USC: { show: true, value: 'GB69SPPV23188492214846' },
      EUR: { show: true, value: 'GB69SPPV23188492214846' },
      GBP: { show: true, value: 'GB69SPPV23188492214846' },
      JPY: { show: true, value: 'GB69SPPV23188492214846' },
      CAD: { show: true, value: 'GB69SPPV23188492214846' },
      SGD: { show: true, value: 'GB69SPPV23188492214846' },
      NZD: { show: true, value: 'GB69SPPV23188492214846' },
      HKD: { show: true, value: 'GB69SPPV23188492214846' },
    },
    beneficiary_address: { show: true, value: 'House of Francis, Room 302, Ile Du Port, Mahe, Seychelles' },
    bsb_number: {
      AUD: { show: true, value: '92214846' },
      USD: { show: true, value: '92214846' },
      USC: { show: true, value: '92214846' },
      EUR: { show: true, value: '92214846' },
      GBP: { show: true, value: '92214846' },
      JPY: { show: true, value: '92214846' },
      CAD: { show: true, value: '92214846' },
      SGD: { show: true, value: '92214846' },
      NZD: { show: true, value: '92214846' },
      HKD: { show: true, value: '92214846' },
    },
    swift_code: { show: true, value: 'SPPVGB2L' },
    sort_code: { show: true, value: '23-18-84' },
  },
  2: {
    bankAddress: { show: true, value: '46 Market Street, Sydney NSW 2000' },
    beneficiaryAccountName: { show: true, value: 'PU Global Pty Ltd' },
    beneficiaryBankSwiftCode: { show: true, value: 'CTBAAU2S' },
    beneficiaryAccountNumber: {
      USC: { show: true, value: '1192 8907' },
      USD: { show: true, value: '1192 8907' },
      EUR: { show: true, value: '1192 8894' },
      AUD: { show: true, value: '1192 8886' },
      GBP: { show: true, value: '1192 8915' },
      CAD: { show: true, value: '8386 3210' },
    },
    beneficiaryBankBsbNumber: {
      USC: { show: true, value: '062 005' },
      USD: { show: true, value: '062 005' },
      EUR: { show: true, value: '062 005' },
      AUD: { show: true, value: '062 005' },
      GBP: { show: true, value: '062 005' },
      CAD: { show: true, value: '062 692' },
    },
    beneficiaryBankName: {
      USC: { show: true, value: 'Commonwealth Bank of Australia' },
      USD: { show: true, value: 'Commonwealth Bank of Australia' },
      EUR: { show: true, value: 'Commonwealth Bank of Australia' },
      AUD: { show: true, value: 'Commonwealth Bank of Australia' },
      GBP: { show: true, value: 'Commonwealth Bank of Australia' },
      CAD: { show: true, value: 'Commonwealth Bank of Australia' },
    },
    paymentReference: {
      show: true,
      value: 'common.field.paymentReferenceContext',
    },
  },
}

module.exports = {
  bank_infos(currency, model = 1) {
    let bank_infos = []
    if (model === 1) {
      bank_infos.push({
        title: 'common.field.bankBeneAccName',
        info: bankInfoDetails[model].account_name,
      })
      bank_infos.push({
        title: 'common.field.bankIbanNum',
        info: bankInfoDetails[model].account_number[currency],
      })
      bank_infos.push({
        title: 'common.field.bankBeneAccNum',
        info: bankInfoDetails[model].bsb_number[currency],
      })
      bank_infos.push({
        title: 'common.field.bankSwiftCode',
        info: bankInfoDetails[model].swift_code,
      })
      bank_infos.push({
        title: 'common.field.bankSortCode',
        info: bankInfoDetails[model].sort_code,
      })
      bank_infos.push({
        title: 'common.field.bankName',
        info: bankInfoDetails[model].bank_name,
      })
      bank_infos.push({
        title: 'common.field.bankAddress',
        info: bankInfoDetails[model].branch_address[currency],
      })
      bank_infos.push({
        title: 'common.field.bankBeneAddress',
        info: bankInfoDetails[model].beneficiary_address,
      })
    } else {
      bank_infos.push({
        title: 'common.field.beneficiaryAccountName',
        info: bankInfoDetails[model].beneficiaryAccountName,
      })
      bank_infos.push({
        title: 'common.field.beneficiaryBankSwiftCode',
        info: bankInfoDetails[model].beneficiaryBankSwiftCode,
      })
      bank_infos.push({
        title: 'common.field.beneficiaryAccountNumber',
        info: bankInfoDetails[model].beneficiaryAccountNumber[currency],
      })
      bank_infos.push({
        title: 'common.field.beneficiaryBankBsbNumber',
        info: bankInfoDetails[model].beneficiaryBankBsbNumber[currency],
      })
      bank_infos.push({
        title: 'common.field.beneficiaryBankName',
        info: bankInfoDetails[model].beneficiaryBankName[currency],
      })
      bank_infos.push({
        title: 'common.field.bankAddress',
        info: bankInfoDetails[model].bankAddress,
      })
      bank_infos.push({
        title: 'common.field.paymentReference',
        info: bankInfoDetails[model].paymentReference,
        needAccountNumber: true,
      })
    }
    return bank_infos
  },
}
