import Vue from 'vue'

const globalMixin = {
  data() {
    return {}
  },
  computed: {
    regulator() {
      return this.$store.state.common.regulator
    },
    countryCode() {
      return this.$store.state.common.countryCode
    },
    GLOBAL_DOMAIN_WEBSITE() {
      return this.$store.state.regulator.domainWebSite || this.$config.info.getUrl(this.regulator)
    },
    GLOBAL_DOMAIN_WEBSITECN() {
      return this.$store.state.regulator.domainWebSiteCn || this.$config.info.getUrl(this.regulator)
    },
    GLOBAL_CONTACT_EMAIL() {
      return this.$store.state.regulator.contactEmail || this.$config.info.getEmail(this.regulator)
    },
    GLOBAL_ACCOUNT_EMAIL() {
      return this.$store.state.regulator.accountEmail || this.$config.info.getAccountEmail(this.regulator)
    },
    GLOBAL_REFERRAL_SITE() {
      return this.$store.state.regulator.referralSite
    },
    GLOBAL_BLACKLIST_EMAIL() {
      return this.$config.info.getBlacklistEmail(this.regulator)
    },
  },
  methods: {
    showLiveChat() {
      zE('messenger', 'open')
    },
    insertJs(src) {
      console.log(src)
      let tag = document.createElement('script')
      tag.src = src
      let firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    },
  },
}

Vue.mixin(globalMixin)
//export globalMixin for test
export default globalMixin
