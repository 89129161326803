// 進行同步執行
import Vue from 'vue'

export default {
  setUserName(state, value) {
    Vue.ls.set('setUserName', value)
    state.userName = value
  },
  setUserType(state, value) {
    state.userType = value
  },
  setLoginStatus(state, value) {
    if (value == '') {
      Vue.ls.set('setUserName', '')
      state.userName = ''
      state.individualUserName = ''
      state.userType = ''
      state.userTypeEnum = ''
      state.pendingQuestionnaireCompletion = false
      state.openAccountEnabled = false
      state.countryCode = ''
      state.accessToken = ''
      state.email = ''
      state.url = ''
      state.isChangedLangAtLandingPage = false
      state.registerStep = 1
      state.isDemo = false
      state.tradingToolUrl = {}
      state.id3Pass = false
      state.regulator = null
      state.failedSecondTime = false
      state.employmentFinanceAnswers = []
      state.tradingAnswers = []
      state.autochartisToken = {}
      state.autochartisExpiryTime = ''
      state.emailMasked = ''
    }
    state.loginStatus = value
  },
  setUserTypeEnum(state, value) {
    state.userTypeEnum = value
  },
  setQuestionnaire(state, value) {
    state.pendingQuestionnaireCompletion = value
  },
  setOpenAccountAction(state, value) {
    state.openAccountEnabled = value
  },
  setIndividualName(state, value) {
    state.individualUserName = value
  },
  setCountryCode(state, value) {
    state.countryCode = value
  },
  setAccessToken(state, value) {
    state.accessToken = value
  },
  setIbUrl(state, value) {
    state.url = value
  },
  setLang(state, value) {
    state.lang = value
  },
  setIsChangedLangAtLandingPage(state, value) {
    state.isChangedLangAtLandingPage = value
  },
  setRegisterStep(state, value) {
    state.registerStep = value
  },
  setDemo(state, value) {
    state.isDemo = value
  },
  setTradingToolUrl(state, value) {
    state.tradingToolUrl = value
  },
  setEmail(state, value) {
    state.email = value
  },
  setId3Pass(state, value) {
    state.id3Pass = value
  },
  setRegulator(state, value) {
    state.regulator = value
  },
  setFailedSecondTime(state, value) {
    state.failedSecondTime = value
  },
  getEmploymentFinanceAnswers(state, value) {
    state.employmentFinanceAnswers = value
  },
  getTradingAnswers(state, value) {
    state.tradingAnswers = value
  },
  setAutochartistToken(state, value) {
    state.autochartisToken = value
  },
  setAutochartisExpiryTime(state, value) {
    state.autochartisExpiryTime = value
  },
  setAutoNotifyStatus(state, value) {
    state.autoNotifyStatus = value
  },
  setEmailMasked(state, value) {
    state.emailMasked = value
  },
  setCheckedAuthStatus(state, value) {
    state.checkedAuthStatus = value
  },
  setAuthHeaders(state, value) {
    state.authHeaders = value
  },
  setRequiredAuths(state, value) {
    state.requiredAuths = value
  },
  setLoginCredentials(state, value) {
    state.loginCredentials = value
  },
  setUserId(state, value) {
    state.userId = value
  },
  setEventEmailId(state, value) {
    state.eventEmailId = value
  },
  setEventMobileId(state, value) {
    state.eventMobileId = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      if (key != 'uuid' && key != 'lang') state[key] = ''
    })
  },
}
